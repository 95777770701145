<template>
	<el-dialog :title="$dict('修改挂车')" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='140px'>
			<el-form-item :label="$dict('车牌号')">
				<el-input v-model="data.truck_trailer_plate_num" disabled></el-input>
			</el-form-item>
			<el-form-item :label="$dict('挂车类型')">
				<el-select v-model="form.truck_trailer_type_name" style="width:100%" clearable>
					<el-option v-for="(item,index) in truck_trailer_type_list" :key="index" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$dict('备注')">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">{{ $dict('提交') }}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		props:{
			is_show:Number,
			data:Object,
		},
		data() {
			return {
				
				//基础数据
				truck_trailer_type_list:[],

				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					truck_trailer_type_name:'',
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		computed:{
			...mapState(['basic_data'])
		},
		created() {

			this.truck_trailer_type_list=this.basic_data.truck_trailer_type.arr;
		},
		methods: {

			//初始化
			init(){
				this.form.truck_trailer_type_name=this.data.truck_trailer_type_name
				this.form.mark=this.data.mark
			},

			//提交
			sub(){

				if(!this.form.truck_trailer_type_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少车型"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_trailer_edit_by_truck_carrier',
						truck_trailer_plate_num:this.data.truck_trailer_plate_num,
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("edited");
					}
				});
			}
		}
	}
</script>