<template>
	<el-dialog :title="$dict('添加挂车')" top="1vh" width="600px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form label-width="140px">
			<el-form-item :label="$dict('车队')">
				<el-input v-model="truck_carrier_info.name" disabled></el-input>
			</el-form-item>
			<el-form-item :label="$dict('车牌号')">
				<el-input v-model="truck_trailer_info.truck_trailer_plate_num" :disabled="truck_trailer_info.has_ser">
					<el-button slot="append" @click="truck_trailer_ser">校验</el-button>
				</el-input>
			</el-form-item>
			<el-form-item :label="$dict('挂车类型')">
				<el-select v-model="form.truck_trailer_type_name" style="width:100%" clearable>
					<el-option v-for="(item,index) in truck_trailer_type_list" :key="index" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$dict('备注')">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<div style="text-align:center;">
				<el-button @click="sub" type="primary">{{ $dict('提交') }}</el-button>
			</div>
		</el-form>
  	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		props: {
			is_show: Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page: false,

				form:{
					truck_trailer_type_name:'',
					mark:'',
				},

				//司机
				truck_trailer_info:{
					has_ser:false,
					truck_trailer_plate_num:'',
				},
			};
		},
		computed:{
			...mapState(['basic_data','truck_carrier_info'])
		},
		created() {

			this.truck_trailer_type_list=this.basic_data.truck_trailer_type.arr;
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
 	 	methods: {

			//提交
			sub(){
				if(!this.truck_trailer_info.has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"挂车校验未通过, 请点击'校验'按钮"
					});
					return;
				}
				if(!this.$my.check.is_plate_num(this.truck_trailer_info.truck_trailer_plate_num)){
					this.$my.other.msg({
						type:'warning',
						str:"缺少车牌号"
					});
					return;
				}
				if(!this.form.truck_trailer_type_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"请选择挂车类型"
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'添加",
					confirm:()=>{
						this.$my.net.req({
							loading:true,
							data:{
								mod:'truck',
								ctr:'truck_trailer_add_by_truck_carrier',
								truck_trailer_plate_num:this.truck_trailer_info.truck_trailer_plate_num,
								truck_trailer_type_name:this.form.truck_trailer_type_name,
								mark:this.form.mark,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"添加成功"
								});
								this.is_show_in_page=false;
								this.$emit('added')
							},
						});
					}
				});
			},

			//挂车查找
			truck_trailer_clear(){//清空
				this.truck_trailer_info.info_text=''
				this.truck_trailer_info.truck_trailer_plate_num=''
			},
			truck_trailer_ser(){//搜索

				if(!this.$my.check.is_plate_num(this.truck_trailer_info.truck_trailer_plate_num)){
					this.$my.other.msg({
						type: "warning",
						str: "缺少车牌号",
					});
					return;
				}

				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_trailer_list_by_truck_carrier',
						truck_trailer_plate_num:this.truck_trailer_info.truck_trailer_plate_num,
					},
					callback:(data)=>{
						if(data.list.length>0){
							this.$my.other.msg({
								type: "warning",
								str: "此挂车已存在,请前往绑定页面",
							});
							return;
						}
						this.truck_trailer_info.has_ser=true;
					}
				})
			},

			//初始化
			init() {
				this.form.mark=''
				this.truck_trailer_clear();
			},
  		},
	};
</script>
<style lang="scss" scoped>

	.bottomBtns {
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}
</style>